import { UserService } from './../../shared/user.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuctionService } from './../auction.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuctionBidWithHistory, BidHistory, CreateBidDto, PropertyDetails } from '../../models/all.models';
import { interval, Subscription } from 'rxjs';
import { startWith, switchMap, finalize } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WideAngleViewerComponent } from '../wide-angle-viewer/wide-angle-viewer.component';
import { ViewportScroller } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ToasterService } from '../../shared/toaster/toaster.service';

@Component({
  selector: 'app-auction-details',
  templateUrl: './auction-details.component.html',
  styleUrls: ['./auction-details.component.scss'],
  animations: [
    trigger('slideToggle', [
      state('void', style({ height: '0px', overflow: 'hidden', opacity: 0 })),
      state('*', style({ height: '*', overflow: 'hidden', opacity: 1 })),
      transition('void <=> *', animate('200ms ease-in-out')),
    ]),
  ],
})
export class AuctionDetailsComponent implements OnInit, OnDestroy {
  id: number;
  treeQuantitiesTotal: number = 0;
  auctionProperty: PropertyDetails;
  auctionForm: UntypedFormGroup;
  userSubscription: Subscription = Subscription.EMPTY;
  actualPrice: number;
  safevirtualTourUrl;
  safeYoutubeVideoUrl;
  bidValidationError: string[] = [];
  userIsLoggedIn = false;
  userCanBid = false;
  isLoading = false;
  inputIsOnFocus = false;
  youare = false;
  modalRef?: BsModalRef;
  bidHistory: BidHistory[] = [];
  initialBidHistoryLoading = true;
  kadester = '';
  kadesterSections : any = [];
  bidIsLoading = false;

  autctionEnded = false;
  private subscription: Subscription;
  private subscriptionPrice: Subscription = Subscription.EMPTY;
  public dDay;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;
  isTimeLessThanTwoMins = false;
  bidValue = 100;
  isCollapsed = false;

  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;
  constructor(
    private activatedRoute: ActivatedRoute,
    private auctionService: AuctionService,
    private userService: UserService,
    private modalService: BsModalService,
    private router: Router,    
    private viewportScroller: ViewportScroller,
    private sanitizer: DomSanitizer,
    private toaster: ToasterService,
  ) { 
    this.id = +this.activatedRoute.snapshot.params["id"] as number;
    this.createForm();
    this.userSubscription = this.userService.applicationUserSubject.subscribe(data => {

      this.userIsLoggedIn = Boolean(data)
      this.userCanBid = this.userService.userHasRoles(['HasSignedContract', 'HasPaidDeposit']);
      }
    );
  }

  ngOnInit() {
    this.isLoading = true;
    this.auctionService.getPropertyById(this.id)
    .pipe(finalize(() => this.isLoading = false))
      .subscribe(data => {
        this.auctionProperty = data;
        this.safevirtualTourUrl = this.auctionProperty.virtualTourUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(this.auctionProperty.virtualTourUrl) : undefined;
        this.safeYoutubeVideoUrl = this.auctionProperty.youtubeVideoUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(this.auctionProperty.youtubeVideoUrl) : undefined;
        var strings = this.auctionProperty.kadestsNumber.split(';');
        this.kadester = strings[0];
        strings.shift();
        this.kadesterSections = strings;
        this.dDay = new Date(data.auctionEnds);
        this.getTimeDifference();

        if(!this.autctionEnded) 
        {
          this.subscription = interval(1000)
          .subscribe(x => { this.getTimeDifference(); });
        }     

        this.auctionProperty.propertyTreeQuantities.forEach(q => {
          this.treeQuantitiesTotal += q.quantity;
        });
      });

    this.subscriptionPrice = interval(1000)
      .pipe(
        startWith(0),
        switchMap(() => this.auctionService.getActualPrice(this.id)),
      ).subscribe(data =>{
        if(data) {        
          if(this.auctionProperty) {
            this.actualPrice = data.price;
            let min = data.price + this.bidValue;

            if(this.auctionProperty.auctionEnds !== data.auctionEndDate) {
              this.auctionProperty.auctionEnds == data.auctionEndDate;
              this.dDay = new Date(data.auctionEndDate);
              this.getTimeDifference()
            }
            this.auctionProperty.auctionEnds = data.auctionEndDate;
            this.auctionForm.controls["price"].setValue(min);
            this.setFormValidation(data.price);

            this.youare = this.userService?.applicationUser?.userId && data?.userId == this.userService?.applicationUser?.userId;
            this.bidHistory = data.bidHistory;
            if(this.autctionEnded){
              this.subscriptionPrice.unsubscribe();
            }

            if(this.initialBidHistoryLoading) {
              setTimeout(() => {
                this.initialBidHistoryLoading = false;                
              }, 100);
            }
          } 
        }
      });
  }

  private setFormValidation(price: number) {
    this.auctionForm.controls["price"].clearValidators();
    this.auctionForm.controls["price"].setValidators([Validators.min(price + 99), Validators.max(price + 1001)]);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if(this.subscription) {
      this.subscription.unsubscribe();
    }

    if(this.subscriptionPrice) {
      this.subscriptionPrice.unsubscribe();
    }

    this.toaster.resetToasts();    
  }

  submitForm() {
    this.bidValidationError = [];
    if (this.auctionForm.valid) {

      var bid = new CreateBidDto();
      bid.price = this.auctionForm.controls["price"].value;
      bid.propertyId = this.auctionForm.controls["propertyId"].value;

      this.bidIsLoading = true;
      this.auctionService.createBid(bid)
        .pipe(finalize(() => this.bidIsLoading = false))
        .subscribe(data => {
          this.youare = true;
        },
        err => {
          this.toaster.show('error', 'Neizdevās veikt solījumu!', err.error, 50000);
          this.setFormValidation(this.actualPrice)
        }
      );
    }
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  openNewRegionModal(): void {
    this.modalRef = this.modalService.show(WideAngleViewerComponent,
      { class: 'forms-modal' });
  }

  navigate() {
    this.router.navigate(['/']);
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor("contact");
    }, 100);
  }

  private createForm(): void {
    this.auctionForm = new UntypedFormGroup({
      propertyId: new UntypedFormControl(this.id, Validators.required),
      price: new UntypedFormControl({ value: '', disabled: false }, Validators.required, ),
    });
  }

  setBidValue(amount: number) {
    this.bidValue = amount;
    this.auctionForm.controls["price"].setValue(this.actualPrice + amount);
  }

  private getTimeDifference () {
    const now = new Date().getTime();
    const ends = this.dDay.getTime();
    this.autctionEnded = now > ends;    
    this.timeDifference = ends - now;
    this.isTimeLessThanTwoMins = this.timeDifference < 120000;
    this.allocateTimeUnits(this.timeDifference);
}

private allocateTimeUnits (timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
}
}
